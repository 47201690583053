// IMPORTS
import './App.scss';
import { useEffect, useState } from 'react';
import $ from 'jquery';
import Carousel from 'nuka-carousel';



// COMPONENT
export default function App() {
   // States and Variables
   var debugapiurl = ""; //http://localhost:3001
   const [timeAndDate, setTimeAndDate] = useState({});
   const [alerts, setAlerts] = useState();
   const [isAlerts, setIsAlerts] = useState(false);
   const [weather, setWeather] = useState();
   const [isWeather, setIsWeather] = useState(false);
   const [deviceName, setDeviceName] = useState("Querying name...");
   const [isDeviceNamed, setIsDeviceNamed] = useState(false);
   
   // On-load
   useEffect(function () {
      // Time and Date
      setInterval(function () {
         getTimeAndDate();
      }, 1000);

      // Private IP Address
      getDeviceName();

      // Alerts
      getAlerts();

      // Weather
      getWeather();

      // Auto-reload
      setTimeout(function () {
         window.location.reload();
      }, 60 * 60 * 1000);
   }, []);

   // Functions
   function getTimeAndDate () {
      // Get Time
      var date = new Date();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; //changes hour 0 to 12
      minutes = minutes < 10 ? '0' + minutes : minutes; //adds additional zero to beginning of minutes if single minute
      seconds = seconds < 10 ? '0' + seconds : seconds; //adds additional zero to beginning of minutes if single second

      // Get Date
      var dayname = date.toLocaleString('en-us', {weekday: 'long'});
      var day = date.getDate();
      var month = date.toLocaleString('en-us', {month: 'long'});
      var year = date.getFullYear();

      // Get Greeting
      var greeting = "Day";
      if (ampm == 'AM') {
         greeting = "Morning";
      } else if ((ampm == 'PM') && ((hours == 12) || (hours >= 1 && hours <= 5))) {
         greeting = "Afternoon";
      } else if ((ampm == 'PM') && (hours >= 6 && hours <= 11)) {
         greeting = "Evening";
      }

      // Set State
      setTimeAndDate({
         time: hours + ':' + minutes + ' ' + ampm,
         date: dayname + ', ' + month + ' ' + day  ,
         greeting: greeting
      });
   }
   function getDeviceName () {
      $.ajax({
         url: debugapiurl + "/services/App/get_device_name.py",
         method: "GET",
         dataType: "json"
      }).done(function (data) {
         if (data.status == "success") {
            setDeviceName(data.payload.name);
            setIsDeviceNamed(true);
            setTimeout(function () {
               getDeviceName();
            }, 15 * 60 * 1000);
         } else {
            if (isDeviceNamed === false) {
               setDeviceName(null);
            }
            setTimeout(function () {
               getDeviceName();
            }, 30 * 1000);
         }
      }).fail(function () {
         if (isDeviceNamed === false) {
            setDeviceName(null);
         }
         setTimeout(function () {
            getDeviceName();
         }, 30 * 1000);
      });
   }
   function getAlerts () {
      $.ajax({
         url: debugapiurl + "/services/App/get_alerts.py",
         method: "GET",
         dataType: "json"
      }).done(function (data) {
         if (data.status == "success") {
            setAlerts(data.payload);
            setIsAlerts(true);
            setTimeout(function () {
               getAlerts();
            }, 2 * 60 * 1000);
         } else {
            if (isAlerts === false) {
               setAlerts({});
            }
            setTimeout(function () {
               getAlerts();
            }, 60 * 1000);
         }
      }).fail(function () {
         if (isAlerts === false) {
            setAlerts({});
         }
         setTimeout(function () {
            getAlerts();
         }, 60 * 1000);
      });
   }
   function getWeather () {
      $.ajax({
         url: debugapiurl + "/services/App/get_weather.py",
         method: "GET",
         dataType: "json"
      }).done(function (data) {
         if (data.status == "success") {
            data.payload.forEach(function (item, index) {
               item.DateTimeHourly = extractHourFromDateTime(item.DateTime);
               item.WeatherIconPath = convertIconNumberToImage(item.WeatherIcon);
            });
            setWeather(data.payload);
            setIsWeather(true);
            setTimeout(function () {
               getWeather();
            }, 15 * 60 * 1000);
         } else {
            if (isWeather === false) {
               setWeather({});
            }
            setTimeout(function () {
               getWeather();
            }, 60 * 1000);
         }
      }).fail(function () {
         if (isWeather === false) {
            setWeather({});
         }
         setTimeout(function () {
            getWeather();
         }, 60 * 1000);
      });
   }
   function extractHourFromDateTime (time) {
      let temp = /T(.\d):/.exec(time)[1];
      var result = "N/A";
      if (temp == 0) {
         result = "12 AM";
      } else if ((temp >= 1) && (temp <= 11)) {
         result = temp + " AM";
      } else if (temp == 12) {
         result = "12 PM";
      } else if ((temp >= 13) && (temp <= 23)) {
         result = (temp - 12) + " PM";
      }
      return result;
   }
   function convertIconNumberToImage (number) {
      var parentDir = '/assets/weathericons/';
      switch (number) {
         case 1:
         case 2:
         case 3:
         case 5:
         case 30:
         case 31:
            return parentDir + 'sun.png';
         case 33:
         case 34:
         case 35:
         case 37:
            return parentDir + 'moon.png';
         case 4:
         case 6:
            return parentDir + 'partlycloudy_sun.png';
         case 36:
         case 38:
            return parentDir + 'partlycloudy_moon.png';
         case 7:
         case 8:
            return parentDir + 'cloudy.png';
         case 11:
            return parentDir + 'foggy.png';
         case 12:
         case 13:
         case 14:
         case 18:
         case 25:
         case 26:
         case 29:
         case 39:
         case 40:
            return parentDir + 'rain.png';
         case 15:
         case 16:
         case 17:
         case 41:
         case 42:
            return parentDir + 'stormy.png';
         case 19:
         case 20:
         case 21:
         case 22:
         case 23:
         case 24:
         case 43:
         case 44:
            return parentDir + 'snow.png';
         case 32:
            return parentDir + 'windy.png';
         default:
            return parentDir + 'sun.png';
      }
   }

   // Component
   return (
      <div className="App">
         <div className='App-header'>
            <div className='App-logo'>
               <img src={`/assets/logos/MVHorizontal.png`} alt='MV Logo' />
            </div>
            <div className='App-time'>
               <h1>{timeAndDate.time}</h1>
               <h1>{timeAndDate.date}</h1>
            </div>
         </div>
         <div className='App-wrapper'>
            <div className='App-left'>
               <div className='App-instructions'>
                  <div className='App-instructions-content'>
                     <p>Open the Vivi App and Connect to</p> <span>{deviceName ? deviceName : "NAME NOT FOUND - trying again..."}</span>
                  </div>
               </div>
               <div className='App-mantras'>
                  <Carousel
                     autoplay={true}
                     autoplayInterval={50000}
                     wrapAround={true}
                     cellAlign={'center'}
                     cellSpacing={100}
                     withoutControls={true}
                     swiping={false}
                     dragging={false}
                     speed={750}
                  >
                     <img src='/assets/mantras/BEBRAVEblue.png' alt='Mantras' />
                     <img src='/assets/mantras/GROWANDGIVEblue.png' alt='Mantras' />
                     <img src='/assets/mantras/LEADblue.png' alt='Mantras' />
                     <img src='/assets/mantras/STARTWITHQUESTIONSblue.png' alt='Mantras' />
                     <img src='/assets/mantras/HAVEFUNblue.png' alt='Mantras' />
                     <img src='/assets/mantras/HELPMESEEblue.png' alt='Mantras' />
                  </Carousel>
               </div>
            </div>
            <div className='App-right'>
               <div className='App-weather'>
                  <div className='App-weather-content'>
                     {weather ? weather.length > 0 ? weather.map(function (weatheritem, index) {
                        if (index < 6) {
                           return (
                              <div key={`weather-${index}`} className='App-weather-item'>
                                 <div className='App-weather-item-icon'>
                                    <img src={weatheritem.WeatherIconPath} alt="Weather Icon" />
                                 </div>
                                 <h1>{weatheritem.Temperature.Value}&#176;F</h1>
                                 <div className='App-weather-item-rain'>
                                    <h3>{weatheritem.RainProbability}%</h3>
                                    <img src='/assets/icons/raindrop.png' alt='Rain Drop' />
                                 </div>
                                 <h2>{weatheritem.DateTimeHourly}</h2>
                              </div>
                           );
                        }
                     }) : <h6>Failed to load weather - trying again...</h6> : <h6>Loading weather...</h6>}
                  </div>
               </div>
               {alerts ? alerts.length > 0 ? <div className='App-alerts'>
                  <div className='App-alerts-header'>
                     <h1>Important Info</h1>
                  </div>
                  <div className='App-alerts-content'>
                     {alerts.map(function (alert, index) {
                        if (alert.status == "warning") {
                           return (
                              <p key={`alert-${index}`}><span className='App-alerts-status-warning'>Notice</span> - {alert.details}</p>
                           );
                        } else if (alert.status == "danger") {
                           return (
                              <p key={`alert-${index}`}><span className='App-alerts-status-danger'>!! ATTENTION !!</span> - {alert.details}</p>
                           );
                        } else {
                           return (
                              <p key={`alert-${index}`}>{alert.details}</p>
                           );
                        }
                     })}
                  </div>
               </div> : <></> : <></>}
               <div className='App-links'>
                  <div className='App-links-top'>
                     <h1>Help & Support</h1>
                     <img src="/assets/qrcodes/helpandsupport.png" alt="QR Code" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}